<template>
  <div class="post py-4 px-4" id="kt_post">
    <div class="">
      image photo
      <div class="row text-center">
        <div class="col">
          <button @click="$router.go(-1)" class="btn btn-light me-5">
            Kembali
          </button>
        </div>
        <div class="col">
          <button class="btn btn-success me-5">Simpan Data</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
